<template>
<div id="not-available">
  <el-empty description="暂停服务" />
</div>
</template>

<script>
  export default {
    name: 'notAvailable',
    data() {
      return {

      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
